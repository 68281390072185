@font-face {
  font-family: "Lemon";
  font-style: normal;
  font-weight: normal;
  src: url("./../public/fonts/Lemon-Regular.ttf");
}

.app {
  text-align: center;
  display: grid;
}

.app-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.picture-img {
  display: flex;
  width: 100%;
}

.image-row {
  display: inline-flex;
  margin-bottom: 8px;
}

.picture-title {
  font-size: 16px;
  margin: 4px 0;
}

a {
  color: rgb(119, 217, 249);
}

a:hover {
  color: yellow;
}

li {
  padding-top: 8px;
}

.page-title {
  font-family: "Lemon", "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0;
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 1.2;
  letter-spacing: -0.00833em;
}
